<template>
	<div>
		<p>Resetear contraseña</p>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
